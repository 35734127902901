import { Adress, AvailableFareModel, BookingAddressBehavior, FareBookingPassengerView } from "WebApiClient";
import { AddressValid, BookAddressValidationResult, DefaultBookAddressValidationResult, InvoiceValid } from "./FareBooking.Validate.Address";
import { BookOtherValidationResult, DefaultBookOtherValidationResult, OtherValid } from './FareBooking.Validate.Other';
import { BookPassengerValidationResult, PassengerValid } from "./FareBooking.Validate.Passenger";


export function BookingValid(paymentDataBackend: any, paymentBOFDetails: any, passengers: FareBookingPassengerView[], fare: AvailableFareModel, address: Adress | undefined, invoice: Adress, addressBehavior: BookingAddressBehavior, webfarePassword: string, emergencyPhone: string, webfarePasswordbehavior: BookingAddressBehavior, emergencyPhoneBehavior: BookingAddressBehavior): BookingValidResult {
    let passengersValid: boolean = true;
    let passengersValidation: BookPassengerValidationResult[] = [];

    passengers.forEach((passenger, index) => {
        const passengerValid = PassengerValid(passenger, index, fare, passengers);
        passengersValidation.push(passengerValid)
        if (passengerValid.Valid === false) {
            passengersValid = false;
        }
    });

    let addressValid: BookAddressValidationResult = AddressValid(address, addressBehavior);
    let invoiceValid: BookAddressValidationResult = InvoiceValid(invoice);

    let otherValid: BookOtherValidationResult = OtherValid(fare, webfarePassword, emergencyPhone, webfarePasswordbehavior, emergencyPhoneBehavior, paymentBOFDetails, paymentDataBackend);
    // console.log(otherValid)
    let bookingValid: boolean = passengersValid && addressValid.Valid && otherValid.Valid && invoiceValid.Valid;
    const result: BookingValidResult = {
        Valid: bookingValid,
        PassengersValid: passengersValid,
        Passengers: passengersValidation,
        Address: addressValid,
        Other: otherValid,
        Invoice: invoiceValid
    }
    console.log("-----",result);
    return result;

}

export interface BookingValidResult {
    Valid: boolean;
    PassengersValid: boolean;
    Passengers: BookPassengerValidationResult[];
    Address: BookAddressValidationResult;
    Invoice: BookAddressValidationResult;
    Other: BookOtherValidationResult;
}

export const DefaultBookingValidResult: BookingValidResult = {
    Valid: false,
    PassengersValid: false,
    Passengers: [],
    Address: DefaultBookAddressValidationResult,
    Other: DefaultBookOtherValidationResult,
    Invoice: DefaultBookAddressValidationResult
}